import React, { FC } from "react";
import { Widget } from "../../widgetList/widgetList";
import CustomBuilder from "./CustomBuilder";
import LogicalBuilder from "./LogicalBuilder";

interface Props {
  sourceType: string;
  choosenItem: Widget;
}

const LogicBuilder: FC<Props> = ({ sourceType, choosenItem }) => {
  return (
    <>
      {sourceType === "logical" ? (
        <LogicalBuilder sourceType={sourceType} choosenItem={choosenItem} />
      ) : // <CustomBuilder sourceType={sourceType} choosenItem={choosenItem}/>
      sourceType === "custom" ? (
        <CustomBuilder sourceType={sourceType} choosenItem={choosenItem} />
      ) : null}
    </>
  );
};

export default LogicBuilder;
