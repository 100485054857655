import React, { FC, useEffect, useState, UIEvent, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import Alert from "../../components/global/Alert";
import CloseIcon from "../../components/icons/CloseIcon";
import CopyIcon from "../../components/icons/CopyIcon";
import {
  getImageDetails,
  openImagesDetails,
} from "../../features/ImageDetails/imageDetails";
import {
  fetchImagesModule,
  uploadImage,
  deleteImage,
  createFolder,
} from "../../modules/mediaManager";
import { getImageUrl, toastMe } from "../../utils";
import swal from "sweetalert";

import "./mediaPage.css";
import ArrowIcon from "../../components/icons/CopyIcon";

export interface Image {
  id: number;
  url: string;
}

export interface PagePagination {
  total: number;
  lastPage: number;
  currentPage: number;
  totalResult?: number;
  totalPage?: number;
}

const MediaPageV2: FC = () => {
  const showImagePopUpType = useSelector(
    (state: RootState) => state.showImagePopUp.type
  );
  const permissions = useSelector(
    (state: RootState) => state.authPermission.permissions
  );
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);

  const [images, setImages] = useState<MediaItem[]>([]);
  const [chooseItemId, setChooseItemId] = useState(0);
  const [chooseItem, setChooseItem] = useState<boolean>(false);
  const [imagesForUpload, setImagesForUpload] = useState<any[]>([]);
  const [choosenImages, setChoosenImages] = useState<MediaItem[]>([]);
  const [searchText, setSearchText] = useState("");
  const [search, setSearch] = useState("");

  const [createFolderModal, setCreateFolderModal] = useState<boolean>(false);
  const [folderName, setFolderName] = useState<string>("");
  const [parentIds, setParentIds] = useState<number[]>([]);
  const parentId = useMemo(
    () => (parentIds.length > 0 ? parentIds[parentIds.length - 1] : null),
    [parentIds]
  );

  const [pagePagination, setPagePagination] = useState<PagePagination>({
    total: 0,
    lastPage: 1,
    currentPage: 1,
  });

  console.log(
    pagePagination.total,
    "===",
    images.length,
    pagePagination.total === images.length
  );

  // useEffect(()=>{
  //     getImages(pagePagination)
  // },[])

  useEffect(() => {
    const pagination = {
      total: 0,
      lastPage: 1,
      currentPage: 1,
    };

    setPagePagination(pagination);
    setImages([]);

    getImages(pagination);
  }, [parentId, search]);

  const getImages = async (pagination: PagePagination) => {
    try {
      const imageList = await fetchImagesModule(pagination, parentId!, search);
      pagination?.total === 0
        ? setImages(imageList.data)
        : setImages(images.concat(imageList.data));
      setLoading(false);
      if (imageList?.totalResult) {
        const { data, ...pagination } = imageList;
        setPagePagination({
          ...pagination,
          lastPage: pagination.totalPage,
          total: pagination.totalResult,
        });
      }
    } catch (error) {
      throw error;
    }
  };

  const handlePageScroll = (e: UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;

    if (
      !loading &&
      pagePagination.currentPage < pagePagination.lastPage &&
      target.scrollTop + target.clientHeight + 120 >= target.scrollHeight
    ) {
      const updatedPagination = {
        ...pagePagination,
        currentPage: pagePagination.currentPage + 1,
      };
      getImages(updatedPagination);
      setLoading(true);
      setPagePagination(updatedPagination);
    }
  };

  const handleImagesForUpload = async (e: React.ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    const items = target.files as FileList;
    const arrayOfItems = Object.values(items);
    await setImagesForUpload(arrayOfItems);
  };

  const handleUploadImage = () => {
    // eslint-disable-next-line array-callback-return
    imagesForUpload.map(async (item) => {
      const uploadedImages = await uploadImage(item, parentId!);
      if (uploadedImages) {
        getImages({
          total: 0,
          lastPage: 1,
          currentPage: 1,
        });
      }
    });
  };
  const handleInsertImages = () => {
    // onUpdate(choosenImages as any);
  };
  const deleteChoosenImage = (id: number) => {
    const filter = choosenImages.filter((item) => item.id !== id);
    setChoosenImages(filter);
  };

  const handleCreateFolder = async () => {
    try {
      const folder = await createFolder(folderName, parentId!);
      setFolderName("");
      if (folder) {
        getImages({
          total: 0,
          lastPage: 1,
          currentPage: 1,
        });
      }
    } catch (error) {
      // console.log(error);
    }

    setCreateFolderModal(false);
  };

  const handleDelete = async (id: number) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this file!",
      icon: "warning",
      buttons: [true, true],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        // dispatch(removePageAsync(id));
        deleteImage(id, pagePagination).then((res) => {
          if (res.status === 200) {
            swal("Your file has been deleted!", {
              icon: "success",
            });
            setImages(images.filter((image) => image.id !== id));
          }
        });
      } else {
        swal("Your file is safe!");
      }
    });
  };

  return (
    <div>
      <div className="bg-white  rounded-md flex justify-between items-center pe-5">
        <form
          className="z-10 w-full -top-6 py-3 flex gap-4"
          onSubmit={(e) => {
            e.preventDefault();

            setSearch(searchText);
          }}
        >
          <div className="relative flex items-center gap-4">
            <input
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder="Search"
            />
            {searchText && (
              <button
                className="absolute right-[105px] border border-gray-500 rounded-full p-0.5"
                onClick={() => {
                  setSearchText("");
                  setSearch("");
                }}
                type="button"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="-28 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                  className="fill-current text-gray-500"
                >
                  <title>{"cancel"}</title>
                  <path d="M64 388L196 256 64 124 96 92 228 224 360 92 392 124 260 256 392 388 360 420 228 288 96 420 64 388Z" />
                </svg>
              </button>
            )}
            <button
              type="submit"
              className="capitalize bg-blue-500 px-4 py-2 text-white rounded-md shadow-lg"
            >
              Search
            </button>
          </div>
          {parentId && (
            <div className="z-50">
              <button
                onClick={() => {
                  setParentIds((prev) => prev.slice(0, prev.length - 1));
                }}
                className="flex items-center space-x-2 px-4 py-1 rounded-md bg-white text-gray-500 hover:bg-gray-100 border"
              >
                <span className="cursor-pointer">
                  <svg
                    width={20}
                    height={20}
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    className="fill-gray-500"
                  >
                    <g id="icomoon-ignore" />
                    <path
                      d="M14.389 7.956v4.374l1.056 0.010c7.335 0.071 11.466 3.333 12.543 9.944-4.029-4.661-8.675-4.663-12.532-4.664h-1.067v4.337l-9.884-7.001 9.884-7zM15.456 5.893l-12.795 9.063 12.795 9.063v-5.332c5.121 0.002 9.869 0.26 13.884 7.42 0-4.547-0.751-14.706-13.884-14.833v-5.381z"
                      fill="#000000"
                    />
                  </svg>
                </span>
                <span>Back</span>
              </button>
            </div>
          )}
        </form>

        <div className="flex items-center gap-4">
          <div className="w-full min-w-72 flex items-center justify-center p-4">
            <input
              type="file"
              accept="image/png, image/jpeg, image/svg"
              multiple
              onChange={handleImagesForUpload}
            />
          </div>

          <button
            className="bg-blue-500 text-white px-4 py-2 rounded-md whitespace-nowrap"
            onClick={handleUploadImage}
          >
            Upload Image
          </button>
        </div>
      </div>

      <div
        className="relative w-full h-[85vh] scroll-smooth overflow-y-scroll pt-4"
        onScroll={handlePageScroll}
      >
        <div className="">
          <div className="grid grid-cols-5 gap-6 pb-32">
            {/* create folder button */}
            <div
              className="w-full h-full p-2 shadow-lg relative bg-white cursor-pointer"
              onClick={() => {
                setCreateFolderModal(true);
              }}
            >
              <div className="w-full h-40 aspect-auto object-contain object-center bg-gray-100 cursor-pointer flex items-center justify-center">
                <img
                  src="/img/folder-create.png"
                  alt="folder"
                  className="w-20 h-20"
                />
              </div>
              <div className="absolute bottom-0 left-0 w-full h-10 bg-white bg-opacity-80 flex items-center justify-center">
                <p>Create Folder</p>
              </div>
            </div>

            {/* upload image button */}
            {images?.map((image: MediaItem, i) => {
              if (image.mimetype === "folder")
                return (
                  <div className="relative group" key={image.id + "-" + i}>
                    <div
                      className={`w-full h-full p-2 shadow-lg relative bg-white cursor-pointer`}
                      onClick={() => {
                        setParentIds((prev) => [...prev, image.id]);
                        // setMediaResponse(undefined);
                        setSearch("");
                        setSearchText("");
                        setPagePagination((prev) => ({
                          ...prev,
                          currentPage: 1,
                        }));
                      }}
                    >
                      {/* folder name */}
                      <div className="absolute bottom-0 left-0 w-full h-10 bg-white bg-opacity-80 flex items-center justify-center px-1">
                        <p className="text-xs">{image.name}</p>
                      </div>

                      <div className="w-full h-40 aspect-auto object-contain object-center bg-gray-100 cursor-pointer flex items-center justify-center">
                        <img
                          src="/img/folder.svg"
                          alt="folder"
                          className="w-[70%] h-[60%] object-contain"
                        />
                      </div>
                    </div>

                    <button
                      className="hidden group-hover:block absolute top-2 right-2 border border-red-400 px-2 py-1 rounded-md bg-opacity-80 z-50 hover:bg-red-100 transition-all duration-500"
                      onClick={(e) => {
                        handleDelete(image.id);
                      }}
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7 4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v2h4a1 1 0 1 1 0 2h-1.069l-.867 12.142A2 2 0 0 1 17.069 22H6.93a2 2 0 0 1-1.995-1.858L4.07 8H3a1 1 0 0 1 0-2h4V4zm2 2h6V4H9v2zM6.074 8l.857 12H17.07l.857-12H6.074zM10 10a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0v-6a1 1 0 0 1 1-1zm4 0a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0v-6a1 1 0 0 1 1-1z"
                          className="fill-current text-red-400"
                        />
                      </svg>
                    </button>
                  </div>
                );

              return (
                <div className="relative group" key={image.id + "-" + i}>
                  <div
                    key={image?.id + "-" + i}
                    className={`w-full h-full p-2 shadow-lg cursor-pointer ${
                      chooseItemId === image?.id ? "bg-blue-300" : "bg-white"
                    }`}
                  >
                    <img
                      className="w-full h-40 aspect-auto object-contain object-center bg-gray-100"
                      src={getImageUrl(image?.path)}
                      alt=""
                    />
                  </div>

                  {/* folder name */}
                  <div className="absolute bottom-0 left-0 w-full h-10 bg-white bg-opacity-80 flex items-center justify-center px-1">
                    <p className="text-xs">{image.name}</p>
                  </div>

                  <button
                    className="hidden group-hover:block absolute top-2 right-2 border border-red-400 px-2 py-1 rounded-md bg-opacity-80 z-50 hover:bg-red-100 transition-all duration-500"
                    onClick={(e) => {
                      handleDelete(image.id);
                    }}
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7 4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v2h4a1 1 0 1 1 0 2h-1.069l-.867 12.142A2 2 0 0 1 17.069 22H6.93a2 2 0 0 1-1.995-1.858L4.07 8H3a1 1 0 0 1 0-2h4V4zm2 2h6V4H9v2zM6.074 8l.857 12H17.07l.857-12H6.074zM10 10a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0v-6a1 1 0 0 1 1-1zm4 0a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0v-6a1 1 0 0 1 1-1z"
                        className="fill-current text-red-400"
                      />
                    </svg>
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* input folder with submit button modal */}
      <div
        className={`fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50 ${
          createFolderModal ? "block" : "hidden"
        }`}
      >
        <div
          className="absolute w-full h-full left-0 top-0 -z-10"
          onClick={() => {
            setCreateFolderModal(false);
            setFolderName("");
          }}
        ></div>
        <div className="bg-white rounded-md shadow-lg">
          <div className="flex items-center justify-between mb-4">
            <h1 className="text-xl font-bold">Create Folder</h1>
            <div
              className="cursor-pointer"
              onClick={() => {
                setCreateFolderModal(false);
                setFolderName("");
              }}
            >
              <CloseIcon />
            </div>
          </div>
          <div className="flex items-center justify-between gap-2">
            <input
              type="text"
              placeholder="Folder Name"
              className="w-full rounded-md border border-gray-300 px-4 py-2 focus:outline-none focus:border-blue-500"
              value={folderName}
              onChange={(e) => setFolderName(e.target.value)}
            />
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded-md"
              onClick={handleCreateFolder}
            >
              Create
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaPageV2;
