import axios from "axios";
import Cookies from "js-cookie";
import { CSSProperties, useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import { adminTokenCookieName } from "../../utils/constants";
import { PageCategory } from "../../widgetList/widgetList";
import CloseIcon from "../icons/CloseIcon";
import classes from "./CampaignSelector.module.css";

interface Props {
  initialCampaign?: PageCategory | null;
  onUpdate?: (data: PageCategory | null) => void;
  className?: string;
  style?: CSSProperties;
}

const CampaignSelector = ({
  initialCampaign = null,
  onUpdate = () => {},
  className,
  style,
}: Props) => {
  const [mainPageCampaigns, setMainCampaigns] = useState<PageCategory | null>(
    initialCampaign
  );
  const [pageCampaignText, setPageCampaignText] = useState("");
  const [pageCampaigns, setPageCampaigns] = useState<any[]>([]);
  const [showPageCampaigns, setShowPageCampaigns] = useState(false);

  const handleDeletePageCampaigns = () => {
    // const filteredPageCampaigns = mainPageCampaigns.filter((_,i) => i !== index)
    setMainCampaigns(null);
    onUpdate(null);
    // patchWidgetsData(mainWidgets, 'draft', 'widgets', mainPageCategory, mainPageBrand, filteredPageCampaigns)
    // patchWidgetsData(topWidgets, 'draft', 'topbar', mainPageCategory, mainPageBrand, filteredPageCampaigns)
    // patchWidgetsData(filterWidgets, 'draft', 'sidebar', mainPageCategory, mainPageBrand, filteredPageCampaigns)
  };
  //Function for page campaign search
  const handlePageCampaignsSearch = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageCampaignText(e.target.value);
    const text: string = e.target.value;

    if (text?.length > 0) {
      setShowPageCampaigns((prev) => (prev = !prev));
    }
    getPageCampaigns(text);
  };
  const getPageCampaigns = async (text: string) => {
    // checkToken()

    await axios
      .get(process.env.REACT_APP_CAMPAIGN_URL + "campaigns?s=" + text, {
        headers: {
          Authorization: `Bearer ${Cookies.get(adminTokenCookieName)}`,
        },
      })
      .then((res) => {
        setPageCampaigns(res.data.data);
      });
  };

  const closePageCampaignsResult = () => {
    setShowPageCampaigns(false);
  };
  const handleChoosePageCampaigns = (item: any) => {
    const _id = item.id;
    const _name = item.name;
    const _slug = item.slug;

    const newObj: PageCategory = {
      id: _id,
      name: _name,
      slug: _slug,
    };

    // const _mainPageCampaigns = [...mainPageCampaigns]
    // if(mainPageCampaigns?.length < 1) {
    // 	_mainPageCampaigns.push(newObj)
    // }

    setMainCampaigns(newObj);
    setPageCampaignText("");

    onUpdate(newObj);
  };

  const handleSavePageCampaigns = () => {
    onUpdate(mainPageCampaigns);

    // setMainCategpry([])
    // setMainBrand([])
    // patchWidgetsData(mainWidgets, 'draft', 'widgets', mainPageCategory, mainPageBrand, mainPageCampaigns)
    // patchWidgetsData(topWidgets, 'draft', 'topbar', mainPageCategory, mainPageBrand, mainPageCampaigns)
    // patchWidgetsData(filterWidgets, 'draft', 'sidebar', mainPageCategory, mainPageBrand, mainPageCampaigns)
    // showToast('Added and saved as draft')
  };

  const refPageCampaignsResult = useDetectClickOutside({
    onTriggered: closePageCampaignsResult,
  });

  // const classNames = [classes.CampaignSelector, className];

  return null;

  return (
    <div className="bg-white drop-shadow-lg rounded-md p-2 mb-2">
      <div className="text-sm tracking-wide mb-1">Target Campaign :</div>
      {
        mainPageCampaigns && (
          <div className="relative p-3 bg-white drop-shadow-lg mb-3 rounded-md text-sm font-light">
            <div className="truncate">
              {mainPageCampaigns?.name} ({mainPageCampaigns?.slug})
            </div>
            <div className="">ID: {mainPageCampaigns?.id}</div>
            <div
              className="absolute z-10 -top-2 -right-2 bg-white text-red-500 rounded-full drop-shadow-lg p-1 cursor-pointer"
              onClick={() => handleDeletePageCampaigns()}
            >
              <CloseIcon />
            </div>
          </div>
        )
        // mainPageCampaigns?.map((item: PageCategory, index) =>{
        // 	return (

        // 	)
        // })
      }
      {!mainPageCampaigns && (
        <div className="relative">
          <input
            value={pageCampaignText}
            type="text"
            placeholder="Search Campaigns"
            onChange={handlePageCampaignsSearch}
          />
          {showPageCampaigns && (
            <div
              ref={refPageCampaignsResult}
              className="absolute top-10 left-0 right-0 max-h-40 min-h-fit overflow-y-auto bg-white my-2 px-2 divide-y z-10"
            >
              {pageCampaigns?.map((item, index) => {
                return (
                  <div
                    onClick={() => handleChoosePageCampaigns(item)}
                    key={index}
                    className="p-2 text-sm tracking-wide truncate cursor-pointer"
                  >
                    {item.name}
                  </div>
                );
              })}
            </div>
          )}
          {/* <div className='w-full bg-green-500 text-white text-center text-sm tracking-wider py-2 rounded-md mt-2 cursor-pointer' onClick={handleSavePageCampaigns}>Add Campaign</div> */}
        </div>
      )}
    </div>
  );
};

export default CampaignSelector;
