import { useState } from "react";

export const NameInput = ({
  handleSubmit,
}: {
  handleSubmit: (title: string) => void;
}) => {
  const [cloneTitle, setCloneTitle] = useState("");

  return (
    <div className="w-96 bg-white rounded-md">
      <form
        onSubmit={async (e) => {
          e.preventDefault();

          handleSubmit(cloneTitle);
        }}
      >
        <input
          value={cloneTitle}
          type="text"
          placeholder="page name..."
          onChange={(e) => {
            e.preventDefault();

            setCloneTitle(e.target.value);
          }}
        />
        <button className="btn" type="submit">
          Add new
        </button>
      </form>
    </div>
  );
};
