import Cookies from "js-cookie";
import { CSSProperties, useEffect, useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import { useDispatch } from "react-redux";
import { _axios } from "../../api";
import { getCustomProducts } from "../../features/logicBuilder/logicBuilder";
import { setGlobalPopupElement } from "../../features/popupSlice";
import { ProductItemGet } from "../../types/product";
import { getImageArray } from "../../utils";
import { adminTokenCookieName, envs } from "../../utils/constants";
import { getProductsBySkus } from "../../utils/requests";
import CloseIcon from "../icons/CloseIcon";
import classes from "./CustomProductPopup.module.css";

interface Props {
  initialProducts?: ProductItemGet[];
  onSetProducts?: (products: ProductItemGet[]) => void;
  className?: string;
  style?: CSSProperties;
}

/**
 * @desc concat products unique
 * @param products, newProducts
 * @returns newProductsList
 * @author Azher Uddin <https://github.com/AzherUddin617>
 * @date 2022-12-22 11:52:32
 */
const concatProductsUnique = (
  products: ProductItemGet[],
  newProducts: ProductItemGet[]
) => {
  const newProductsMap = new Map<string, ProductItemGet>();
  newProducts.forEach((product) => {
    newProductsMap.set(product.id + "", product);
  });

  const newProductsSet = new Set<string>();
  newProducts.forEach((product) => {
    newProductsSet.add(product.id + "");
  });

  const newProductsList = products.filter(
    (product) => !newProductsSet.has(product.id + "")
  );
  newProductsList.push(...newProducts);

  return newProductsList;
};

// popup component for show invalid skus and duplicate skus with proceed button
const InvalidSkusPopup = ({
  invalidSkus = [],
  duplicateSkus = [],
  onProceed = () => {},
  onClose = () => {},
  productCount = 0,
  skus = [],
}: {
  invalidSkus?: string[];
  duplicateSkus?: string[];
  onProceed?: () => void;
  onClose?: () => void;
  productCount: number;
  skus?: string[];
}) => {
  return (
    <div className="fixed w-full h-full top-0 left-0 z-50 flex justify-center items-center">
      <div
        onClick={onClose}
        className="absolute -z-10 top-0 left-0 bg-black/10 w-full h-full"
      />

      <div className="bg-white rounded-md p-4 max-w-[500px] w-[80vw] max-h-[90vh] overflow-y-auto">
        <div className="flex items-center">
          <h3 className="text-md flex-1 text-center">
            <strong>{productCount}</strong> products found out of{" "}
            <strong>{skus.length}</strong> skus
          </h3>
          <button onClick={onClose}>
            <CloseIcon />
          </button>
        </div>
        <div className="mt-4 flex gap-4">
          {invalidSkus.length > 0 && (
            <div className="mb-4 border-r border-gray-300 pr-2">
              <h4 className="text-sm font-semibold">
                Invalid Skus ({invalidSkus.length})
              </h4>
              <ul className="mt-2">
                {invalidSkus.map((sku) => (
                  <li key={sku} className="text-sm">
                    {sku}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {duplicateSkus.length > 0 && (
            <div className="mb-4">
              <h4 className="text-sm font-semibold">
                Duplicate Skus ({duplicateSkus.length})
              </h4>
              <ul className="mt-2">
                {duplicateSkus.map((sku) => (
                  <li key={sku} className="text-sm">
                    {sku}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div className="mt-4 flex justify-end">
          <button
            onClick={onProceed}
            className="bg-orange-500 text-white px-4 py-2 rounded-md font-semibold"
          >
            Proceed anyway
          </button>
        </div>
      </div>
    </div>
  );
};

// popup component for bulk sku input
const BulkSkuPopup = ({
  onSetProducts = () => {},
  onAddProducts = () => {},
  onClose = () => {},
}: {
  onSetProducts?: (products: ProductItemGet[]) => void;
  onAddProducts?: (products: ProductItemGet[]) => void;
  onClose?: () => void;
}) => {
  const [text, setText] = useState<string>("");
  const [products, setProducts] = useState<ProductItemGet[]>([]);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const [skus, setSkus] = useState<string[]>([]);
  const [invalidSkus, setInvalidSkus] = useState<string[]>([]);
  const [duplicateSkus, setDuplicateSkus] = useState<string[]>([]);
  const [popupCallback, setPopupCallback] = useState<() => void>(
    () => () => {}
  );

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
  };

  const handleOnSubmit = (type: "INSERT" | "REPLACE") => {
    setLoading(true);
    setError("");
    const skus = text
      .replaceAll("\n", ",")
      .trim()
      .replaceAll(" ", ",")
      .replace(/,{2,}/g, ",")
      .split(",")
      .filter((sku) => sku.trim() !== "");

    // get products by skus
    getProductsBySkus(skus)
      .then((products) => {
        const _products: any[] = products.map((product: any) => {
          return {
            id: product.id,
            name: product.name,
            monarchmart_sku: product.sku,
            sku: product.sku,
            category_id: product.category_id,
            category_slug: product.category_slug,
            discount_price: product.discount_price,
            _id: product._id,
            e_ticket: product.e_ticket,
            free_delivery: product.free_delivery,
            rating: product.rating,
            shop_id: product.shop_id,
            slug: product.slug,
            thumbnail: product.thumbnail,
            unit_price: product.unit_price,
          };
        });

        if (products.length === skus.length) {
          if (type === "REPLACE") {
            onSetProducts(_products);
          } else if (type === "INSERT") {
            onAddProducts(_products);
          }

          onClose();
        } else if (products.length === 0) {
          setError("No products found");
        } else if (products.length !== skus.length) {
          const invalidSkus: string[] = [];
          const uniqueSkus = Array.from(new Set(skus));
          const duplicateSkus = skus.filter(
            (sku, index) => uniqueSkus.indexOf(sku) !== index
          );

          const validSkus: string[] = [];

          const foundIds: {
            [key: string]: boolean;
          } = {};

          for (const sku of uniqueSkus) {
            let found = false;
            for (const product of products) {
              found = true;
              if (product.sku === sku) {
                if (foundIds[product.id]) {
                  duplicateSkus.push(sku);
                } else {
                  foundIds[product.id] = true;
                }

                break;
              } else if (
                !!product.skus.find(
                  (s) =>
                    s.sku === sku ||
                    s.monarchmart_sku === sku ||
                    s.old_sku === sku
                )
              ) {
                if (foundIds[product.id]) {
                  duplicateSkus.push(sku);
                } else {
                  foundIds[product.id] = true;
                }

                break;
              } else {
                found = false;
              }
            }

            if (!found) {
              invalidSkus.push(sku);
            } else {
              validSkus.push(sku);
            }
          }

          setSkus(skus);
          setInvalidSkus(invalidSkus);
          setDuplicateSkus(duplicateSkus);
          setProducts(_products);

          if (invalidSkus.length > 0 || duplicateSkus.length > 0) {
            setPopupCallback(() => () => {
              if (type === "REPLACE") {
                onSetProducts(_products);
              } else if (type === "INSERT") {
                onAddProducts(_products);
              }

              onClose();
            });
          } else {
            if (type === "REPLACE") {
              onSetProducts(_products);
            } else if (type === "INSERT") {
              onAddProducts(_products);
            }

            onClose();
          }
        }
      })
      .catch((err) => {
        setError("Something went wrong");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={classes.BulkSkuPopup}>
      <div className="absolute w-full h-full -z-10" onClick={onClose}></div>
      <div className={classes.contents}>
        <div className={classes.BulkSkuPopup__header}>
          <h3>Bulk SKU Input</h3>

          <button className={classes.BulkSkuPopup__closeBtn} onClick={onClose}>
            <CloseIcon />
          </button>
        </div>
        <div className={classes.BulkSkuPopup__body}>
          <textarea
            placeholder="Enter SKU(s) here..."
            value={text}
            onChange={handleOnChange}
          />
          {error && <p className="text-red-500 px-2">{error}</p>}

          {!loading ? (
            <div className="flex gap-2">
              {/* buttons of insert on replace all */}
              <button
                className="btn btn-primary"
                onClick={() => handleOnSubmit("INSERT")}
              >
                Insert Products
              </button>
              <button
                className="btn btn-primary !bg-orange-500"
                onClick={() => handleOnSubmit("REPLACE")}
              >
                Replace All
              </button>
            </div>
          ) : (
            <button className="btn btn-primary" disabled={loading}>
              {loading ? "Loading..." : "Submit"}
            </button>
          )}
        </div>
      </div>

      {(invalidSkus.length > 0 || duplicateSkus.length > 0) && (
        <InvalidSkusPopup
          invalidSkus={invalidSkus}
          duplicateSkus={duplicateSkus}
          skus={skus}
          productCount={products.length}
          onClose={() => {
            setInvalidSkus([]);
            setDuplicateSkus([]);
          }}
          onProceed={() => {
            setInvalidSkus([]);
            setDuplicateSkus([]);
            popupCallback();
          }}
        />
      )}
    </div>
  );
};

const CustomProductPopup = ({
  initialProducts,
  onSetProducts = () => {},
  className,
  style,
}: Props) => {
  const dispatch = useDispatch();

  const [choosenProducts, setChoosenProducts] = useState<ProductItemGet[]>(
    initialProducts || []
  );
  const [text, setText] = useState<string>("");
  const [products, setProdcuts] = useState<any[]>([]);
  const [show, setShow] = useState<boolean>(true);

  const [showBulkPopup, setShowBulkPopup] = useState<boolean>(false);

  const classNames = [classes.CustomProductPopup, className];

  const handleRemoveProductId = (index: number) => {
    const filteredProducts = choosenProducts.filter((_, i) => i !== index);
    setChoosenProducts(filteredProducts);
    dispatch(getCustomProducts(filteredProducts as any[]));
    onSetProducts(filteredProducts);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
    const _text: string = e.target.value;
    // getProducts(_text.trim());
  };

  useEffect(() => {
    const abortController = new AbortController();

    const timeout = setTimeout(() => {
      const apiUrl = envs.frontendApiUrl?.replace("api/v1", "api/v2");
      try {
        _axios
          .get(apiUrl + "product/storefront/search?search=" + text, {
            headers: {
              Authorization: `Bearer ${Cookies.get(adminTokenCookieName)}`,
            },
            signal: abortController.signal,
          })
          .then((res) => {
            setProdcuts(res.data?.data);
            setShow(true);
          });
      } catch (err) {
        console.error("err", err);
      }
    }, 200);

    return () => {
      clearTimeout(timeout);

      try {
        abortController.signal.throwIfAborted();

        if (abortController.signal.aborted === false) abortController.abort();
      } catch (error) {}
    };
  }, [text]);

  // const getProducts = async (_text: string) => {
  // 	await _axios
  // 		.get(envs.frontendApiUrl + "product/storefront?search=" + _text, {
  // 			headers: {
  // 				Authorization: `Bearer ${Cookies.get(
  // 					adminTokenCookieName
  // 				)}`,
  // 			},
  // 		})
  // 		.then((res) => {
  // 			setProdcuts(res.data?.data);
  // 			setShow(true);
  // 		});
  // };

  const handleChoosenProduct = (product: ProductItemGet) => {
    const _choosenProducts = concatProductsUnique([product], choosenProducts);
    console.log("product, _choosenProducts :>> ", product, _choosenProducts);
    setChoosenProducts(_choosenProducts);

    dispatch(getCustomProducts(_choosenProducts as any[]));
    onSetProducts(_choosenProducts);
    setText("");
    setShow(false);
  };

  const concatProducts = (products: ProductItemGet[]) => {
    const _choosenProducts = concatProductsUnique(products, choosenProducts);
    setChoosenProducts(_choosenProducts);
    dispatch(getCustomProducts(_choosenProducts as any[]));
    onSetProducts(_choosenProducts);
    setText("");
  };

  const replaceProducts = (products: ProductItemGet[]) => {
    setChoosenProducts(products);
    dispatch(getCustomProducts(products as any[]));
    onSetProducts(products);
    setText("");
  };

  const hideProductList = () => {
    setShow(false);
  };

  const handlePopupClose = () => {
    dispatch(setGlobalPopupElement(null));
  };

  const ref = useDetectClickOutside({ onTriggered: hideProductList });

  return (
    <>
      <div
        className={classNames.join(" ") + `${showBulkPopup ? "!hidden" : ""}`}
        style={style}
      >
        <div
          className="absolute w-full h-full -z-10"
          onClick={handlePopupClose}
        ></div>

        <div className={classes.contents}>
          <div className={classes.top}>
            <div className={classes.left}>
              <input
                value={text}
                type="text"
                placeholder="Add custom products..."
                onChange={handleOnChange}
              />
              <div className="absolute w-full top-full left-0 z-50">
                {products?.length > 0 && show && (
                  <div
                    className="bg-white px-3 shadow-lg divide-y max-h-96 min-h-fit overflow-y-auto"
                    ref={ref}
                  >
                    {products?.map((product, index) => {
                      return (
                        <div key={product.p_id + "-" + index}>
                          <div
                            className="flex items-center gap-4 cursor-pointer py-2"
                            onClick={() =>
                              handleChoosenProduct({
                                ...product,
                                id: product.p_id,
                              })
                            }
                          >
                            <img
                              src={
                                process.env.REACT_APP_SELLER_IMAGE_URL +
                                getImageArray(product?.thumbnail)[0]
                              }
                              alt=""
                              className="w-12 h-12 object-contain"
                            />
                            <div className="text">
                              <div className="text-xs font-light w-full">
                                <span className="font-bold">Product:</span>{" "}
                                {product?.name}
                              </div>
                              <div className="text-xs font-light w-full">
                                <span className="font-bold">Slug:</span>{" "}
                                {product?.slug}
                              </div>
                              <div className="text-xs font-light w-full">
                                <span className="font-bold">Sku:</span>{" "}
                                {product?.sku || product?.monarchmart_sku}
                              </div>
                              <div className="text-xs font-light w-full">
                                <span className="font-bold">
                                  Stock Quantity:
                                </span>{" "}
                                {product?.skus?.reduce(
                                  (acc: number, curr: any) =>
                                    acc + curr.stockQuantity,
                                  0
                                )}
                                {product?.stockQuantity}
                              </div>
                              <div className="text-xs font-light flex items-center w-full justify-start">
                                {/* <span className='font-bold'>Price:</span>
												{
													product.discount_price ? <div>
													<span className='text-gray-400 line-through mr-3'>	
													&#2547;{product?.purchase_price}</span><span className='text-red-500'>	
													&#2547;{product?.discount_price}</span></div> : <div className='text-red-500'>{product.purchase_price}</div>
												} */}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>

            <button
              onClick={() => setShowBulkPopup(true)}
              className="bg-orange-500 text-white px-3 py-1 rounded-md text-xs font-bold tracking-wide"
            >
              Add Bulk Products
            </button>

            <button onClick={handlePopupClose} className={classes.closeButton}>
              <CloseIcon />
            </button>
          </div>

          <div className={classes.body}>
            <div className={classes.products}>
              {choosenProducts.map((item: ProductItemGet, index: number) => {
                return (
                  <div
                    key={index}
                    className="relative flex h-full items-center bg-white shadow-lg rounded-md mb-2 last:mb-4"
                  >
                    <div className={classes.productImage}>
                      <img
                        src={
                          process.env.REACT_APP_SELLER_IMAGE_URL +
                          getImageArray(item?.thumbnail)[0]
                        }
                        alt={item?.name}
                      />
                    </div>

                    <div className="capitalize text-xs font-light tracking-wide p-3">
                      <div className="mb-2">
                        <span className="font-bold">Product: </span>
                        {item?.name}
                      </div>
                      <div>
                        <span className="font-bold">Sku: </span>
                        {item?.sku || item?.monarchmart_sku}
                      </div>
                    </div>
                    <div
                      onClick={() => handleRemoveProductId(index)}
                      className="absolute -top-3 -right-3 z-30 bg-white text-red-500 rounded-full p-1 cursor-pointer shadow-lg"
                    >
                      <CloseIcon />
                    </div>
                  </div>
                );
              })}

              {choosenProducts.length === 0 && (
                <div className="flex items-center justify-center h-full">
                  <p className="text-gray-400 text-sm">No products added yet</p>
                </div>
              )}
            </div>

            {choosenProducts.length > 0 && (
              <>
                <div className={classes.BulkSkuPopup__footer}>
                  <p className="px-4 py-2 text-[0.8rem]">
                    Total products: {choosenProducts.length}
                  </p>
                  {/* <ul className='flex gap'>
										{products.map((product) => (
											<li key={product._id}>{product.name}</li>
										))}
									</ul> */}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {showBulkPopup && (
        <BulkSkuPopup
          onClose={() => setShowBulkPopup(false)}
          onSetProducts={replaceProducts}
          onAddProducts={concatProducts}
        />
      )}
    </>
  );
};

export default CustomProductPopup;
