import { useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import {
  SortEndHandler,
  SortableContainer,
  SortableElement,
} from "react-sortable-hoc";
import DeleteIcon from "./icons/DeleteIcon";

function arrayMove<T>(array: readonly T[], from: number, to: number) {
  const slicedArray = array.slice();
  slicedArray.splice(
    to < 0 ? array.length + to : to,
    0,
    slicedArray.splice(from, 1)[0]
  );

  return slicedArray;
}

const SortableItem = SortableElement<any>(
  ({ value, remove }: { value: JSX.Element; remove: () => void }) => (
    <div className="border border-gray-300 bg-white rounded-md p-2 cursor-pointer relative !z-[4000] flex gap-2 justify-between items-center">
      <div tabIndex={0}>{value}</div>
    </div>
  )
);

const SortableList = SortableContainer<any>(
  <T extends object>({
    items,
    renderLabel,
    onRemove,
  }: {
    items: T[];
    readonly renderLabel: (item: T) => JSX.Element;
    onRemove: (index: number) => void;
  }) => {
    return (
      <div className="flex flex-col gap-2">
        {items.map((value, index) => (
          <SortableItem
            key={`item-${(value as any)?.id || index}`}
            index={index}
            value={renderLabel(value)}
            remove={() => onRemove(index)}
          />
        ))}
      </div>
    );
  }
);

const SortList = <T extends any>({
  data,
  renderElement,
  updateData,

  onSearch,
  sortTriger = true,
}: {
  data: T[];
  renderElement: (item: T) => JSX.Element;
  updateData: (data: T[]) => void;

  onSearch?: (value: string) => Promise<T[]>;
  sortTriger?: boolean;
}) => {
  const [searchResults, setSearchResults] = useState<T[]>([]);

  const searchRef = useDetectClickOutside({
    onTriggered: () => setSearchResults([]),
  });

  const [items, setItems] = useState(data);
  const [sortOn, setSortOn] = useState(false);

  const onSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
    setItems((items) => arrayMove(items, oldIndex, newIndex));
  };

  const handleSearch = (e: any) => {
    const value = e.target.value;

    if (!value) {
      setSearchResults([]);
      return;
    } else {
      onSearch &&
        onSearch(value).then((res) => {
          setSearchResults(res);
        });
    }
  };

  const handleChoose = (item: T) => {
    setItems((items) => [item, ...items]);
    setSearchResults([]);
  };

  return (
    <div className="p-4">
      {onSearch && (
        <div className="relative pb-4">
          <input
            type="text"
            placeholder="Search items..."
            onChange={handleSearch}
          />
          {searchResults.length > 0 && (
            <div
              ref={searchRef}
              className="absolute top-10 left-0 right-0 max-h-40 min-h-fit overflow-y-auto bg-white shadow-xl my-2 px-2 divide-y z-[99999]"
            >
              {searchResults?.map((item, index) => {
                return (
                  <div
                    onClick={() => handleChoose(item)}
                    key={index}
                    className="p-2 text-sm tracking-wide truncate cursor-pointer"
                  >
                    {renderElement(item)}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}

      {sortTriger && (
        <div className="flex items-center gap-2 pb-4">
          <div
            onClick={() => {
              setSortOn((prev) => !prev);
            }}
            className={` cursor-pointer w-12 h-fit rounded-full flex items-center p-1 shadow-inner ${
              !sortOn ? "justify-start bg-gray-400" : "justify-end bg-green-500"
            }`}
          >
            <div className="w-4 h-4 rounded-full bg-white drop-shadow-lg cursor-pointer"></div>
          </div>
          <p>SORT</p>
        </div>
      )}

      <div className="min-w-[350px] max-h-[70vh] overflow-y-auto">
        {(!sortTriger || sortOn) && (
          <SortableList
            items={items}
            onRemove={(index: number) => {
              setItems((items) => {
                const newItems = items.slice();
                newItems.splice(index, 1);

                return newItems;
              });
            }}
            renderLabel={renderElement as any}
            onSortEnd={onSortEnd}
          />
        )}

        {sortTriger &&
          !sortOn &&
          items.map((item, index) => {
            return (
              <div className="border border-gray-300 rounded-md p-2 flex justify-between items-center mb-2 last:mb-0">
                {renderElement(item)}

                <button
                  type="button"
                  onClick={() => {
                    setItems((items) => {
                      const newItems = items.slice();
                      newItems.splice(index, 1);

                      return newItems;
                    });
                  }}
                  className="text-red-500 x-2"
                >
                  <DeleteIcon />
                </button>
              </div>
            );
          })}

        {items.length === 0 && (
          <div className="text-center text-gray-500 mt-4">No items</div>
        )}
      </div>

      <hr className="mt-4" />

      <button
        className="bg-blue-500 text-white rounded-md px-4 py-2 mt-4"
        onClick={() => {
          updateData(items);
        }}
      >
        Save
      </button>
    </div>
  );
};

export default SortList;
