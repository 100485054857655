import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { adminTokenCookieName, envs } from "./constants";

export const getTokenCookie = (): string => {
  return Cookies.get(adminTokenCookieName) || "";
};

export const getImageUrl = (path: string): string => {
  return `${envs.imageBasePath}${path}`;
};

export const objectKeys = <T extends object>(obj: T): (keyof T)[] => {
  return Object.keys(obj) as (keyof T)[];
};

export const toastMe = (
  message: string | any,
  type: "success" | "error" | "warning" | "info" = "success",
  extraMsg = ""
): void => {
  if (typeof message === "string") {
    toast(message, {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      type: type,
    });
  } else if (
    typeof message === "object" &&
    objectKeys(message?.response?.data?.error || {}).length > 0
  ) {
    const obj = message?.response?.data.error || {};
    const keys = objectKeys(obj);

    keys.forEach((key) => {
      obj[key].forEach((msg: string) => {
        toastMe(msg, "error");
      });
    });
  } else {
    toastMe(extraMsg || "Something went wrong", type);
  }
};

export const getImageArray = (image?: string) => {
  if (!image) return [];
  return image.split(",");
};
