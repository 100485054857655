import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useNavigate, useSearchParams } from "react-router-dom";
import CloseIcon from "../../components/icons/CloseIcon";
import DeleteIcon from "../../components/icons/DeleteIcon";
import DuplicateIcon from "../../components/icons/DuplicateIcon";
import EditIcon from "../../components/icons/EditIcon";

import "./homepage.css";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import type { AppDispatch, RootState } from "../../app/store";
import {
  Page,
  addPageAsync,
  fetchAllPagesV2,
  removePageAsync,
} from "../../features/addPages/addPages";

import { unwrapResult } from "@reduxjs/toolkit";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { checkToken } from "../../App";
import { NameInput } from "../../components/NameInput";
import PopupWrapper from "../../components/PopupWrapper";
import { getPageId } from "../../features/pageId/pageId";
import { setGlobalPopupElement, setLoading } from "../../features/popupSlice";
import { getPageDetails, updatePageStatus } from "../../modules/mediaManager";
import { setPageActive } from "../../utils/requests";

export const showToast = (message: string) => {
  checkToken();
  const Toast = Swal.mixin({
    toast: true,
    position: "bottom-end",
    showConfirmButton: false,
    timer: 2500,
  });
  Toast.fire({
    icon: "success",
    title: message,
  });
};

export const confirmMessage = ({
  message,
  title,
  icon = "warning",
  onConfirm = () => {},
}: {
  message?: string;
  title: string;
  icon?: "warning" | "success" | "error";
  onConfirm?: () => void;
}) => {
  swal({
    title: title,
    text: message,
    icon: icon,
    buttons: [true, true],
    dangerMode: true,
  }).then((willDelete) => {
    if (willDelete) {
      // swal("Your page file has been deleted!", {
      //   icon: "success",
      // });

      onConfirm();
    } else {
      // swal("Your page file is safe!");
    }
  });
};

export const ChokhIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="#fff"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5799 11.9999C15.5799 13.9799 13.9799 15.5799 11.9999 15.5799C10.0199 15.5799 8.41992 13.9799 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C13.9799 8.41992 15.5799 10.0199 15.5799 11.9999Z"
      stroke="#fff"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.9998 20.2707C15.5298 20.2707 18.8198 18.1907 21.1098 14.5907C22.0098 13.1807 22.0098 10.8107 21.1098 9.4007C18.8198 5.8007 15.5298 3.7207 11.9998 3.7207C8.46984 3.7207 5.17984 5.8007 2.88984 9.4007C1.98984 10.8107 1.98984 13.1807 2.88984 14.5907C5.17984 18.1907 8.46984 20.2707 11.9998 20.2707Z"
      stroke="#fff"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const PAGE_NO_QURY = "page_no";
const SEARCH_TEXT_QURY = "search";

const HomePage: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getAllPages = useSelector(
    (state: RootState) => state.allPages.allPages
  );
  const permissions = useSelector(
    (state: RootState) => state.authPermission.permissions
  );
  const currentPage = useSelector(
    (state: RootState) => state.allPages.currentPage
  );
  const perPage = useSelector((state: RootState) => state.allPages.perPage);
  const totalPages = useSelector(
    (state: RootState) => state.allPages.totalPages
  );
  const searchTextStore = useSelector(
    (state: RootState) => state.allPages.searchText
  );
  const addPageLoading = useSelector(
    (state: RootState) => state.allPages.addPageLoading
  );
  const addPageError = useSelector(
    (state: RootState) => state.allPages.addPageError
  );

  const [allPages, setAllPages] = useState<any[]>(getAllPages);
  const [pageType, setPageType] = useState<string>("page");
  const [title, setTitle] = useState("");
  const [totalPage, setTotalPage] = useState<number>();
  const [searchText, setSearchText] = useState(
    searchParams.get(SEARCH_TEXT_QURY)
      ? searchParams.get(SEARCH_TEXT_QURY) || ""
      : ""
  );
  const [errorMessage, setErrorMessage] = useState(false);
  const [showPagination, setShowPagination] = useState(true);
  const [initial, setInitial] = useState(true);

  const [cloneTitle, setCloneTitle] = useState("");

  // const [pageIndex, setPageIndex] = useState(searchParams.get(PAGE_NO_QURY) ? parseInt(searchParams.get(PAGE_NO_QURY) as string) : 1);
  // const [submitSearchText, setSubmitSearchText] = useState(searchParams.get(SEARCH_TEXT_QURY) ? searchParams.get(SEARCH_TEXT_QURY) || '' : '');

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  // useEffect(()=>{
  //   dispatch(fetchAllPages())
  //   removeSearchText()
  //   setInitial(false)
  // },[])

  useEffect(() => {
    const pageIndex = searchParams.get(PAGE_NO_QURY)
      ? parseInt(searchParams.get(PAGE_NO_QURY) as string)
      : 1;
    const submitSearchText = searchParams.get(SEARCH_TEXT_QURY)
      ? searchParams.get(SEARCH_TEXT_QURY) || ""
      : "";
    dispatch(
      fetchAllPagesV2({
        currentPage: pageIndex,
        searchText: submitSearchText,
      })
    );
  }, [searchParams, dispatch]);

  useEffect(() => {
    calculateTotalPages();
  }, [totalPages]);

  // useEffect(()=>{
  //   if(!initial){
  //     dispatch(fetchAllPages())
  //   }
  // },[currentPage, searchTextStore])

  useEffect(() => {
    if (addPageError) {
      setErrorMessage(true);
    }
  }, [addPageError]);

  // page active

  const [activeSetLoading, setActiveSetLoading] = useState<{
    [key: string]: boolean;
  }>({});

  const handlePageActive = (pageId: string, prevState: boolean) => {
    if (activeSetLoading[pageId]) return;

    const handler = async (active: boolean) => {
      setActiveSetLoading((prev) => ({ ...prev, [pageId]: true }));

      await setPageActive(pageId, active).then((page) => {
        // dispatch(fetchAllPages())
        const pageIndex = searchParams.get(PAGE_NO_QURY)
          ? parseInt(searchParams.get(PAGE_NO_QURY) as string)
          : 1;
        const submitSearchText = searchParams.get(SEARCH_TEXT_QURY)
          ? searchParams.get(SEARCH_TEXT_QURY) || ""
          : "";

        dispatch(
          fetchAllPagesV2({
            currentPage: pageIndex,
            searchText: submitSearchText,
          })
        );
      });

      setActiveSetLoading((prev) => ({ ...prev, [pageId]: false }));
    };

    if (prevState) {
      confirmMessage({
        title: "Are you sure?",
        message: "You are going to deactivate this page.",
        onConfirm: () => handler(false),
      });
    } else {
      confirmMessage({
        title: "Are you sure?",
        message: "You are going to activate this page.",
        onConfirm: () => handler(true),
      });
    }
  };

  const calculateTotalPages = () => {
    const _totalPage: number = Math.ceil(totalPages / perPage);
    setTotalPage(_totalPage);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const result = e.target.value;
    setTitle(result);
  };

  const handleSumbit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const _title = title.toLowerCase();
    const actionObj = {
      name: _title,
      widgets: [],
      sidebar: [],
      topbar: [],
      status: "draft",
      type: pageType,
    };
    dispatch(addPageAsync(actionObj))
      .then(unwrapResult)
      .then((obj) => {
        if (obj) {
          showToast("Page created successfully!");
          navigate(`/pages/${obj._id}`);
        }
      })
      .catch((error) => {
        if (error.code === "ERR_BAD_REQUEST") {
          swal("Page creation faild! Plz try with different name!");
        } else {
          swal("test");
        }
      });
    setTitle("");
    // if(errorMessage){
    //   swal("Page creation faild! Plz try with different name!");
    // }
  };

  const handleDeletePage = (id: string) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this page file!",
      icon: "warning",
      buttons: [true, true],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Your page file has been deleted!", {
          icon: "success",
        });
        dispatch(removePageAsync(id));
      } else {
        swal("Your page file is safe!");
      }
    });
  };

  const handlePageClick = (data: any) => {
    const _currentPage: number = data.selected + 1;
    // dispatch(handleChnageCurrentPage(_currentPage))
    // dispatch(handlePerPage(10))
    // setPageIndex(_currentPage);
    searchParams.set(PAGE_NO_QURY, _currentPage.toString());
    setSearchParams(searchParams);

    if (searchText.length > 0) {
      setShowPagination(false);
    } else {
      setShowPagination(true);
    }
  };

  const handleOnChangeSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const _searchText = e.target.value;
    setSearchText(_searchText);
  };

  const handleSearchText = () => {
    // dispatch(updateSearchText(searchText))
    // dispatch(handleChnageCurrentPage(1))
    // dispatch(fetchAllPages())
    // dispatch(fetchAllPagesV2({
    //   currentPage: 1,
    //   searchText: searchText,
    // }))

    // setSubmitSearchText(searchText);
    // setPageIndex(1);
    searchParams.set(PAGE_NO_QURY, "1");
    searchParams.set(SEARCH_TEXT_QURY, searchText);
    setSearchParams(searchParams);

    if (searchText.length > 0) {
      setShowPagination(false);
    } else {
      setShowPagination(true);
    }
  };

  const removeSearchText = () => {
    setSearchText("");
    // setSubmitSearchText('');
    // setPageIndex(1);
    searchParams.set(PAGE_NO_QURY, "1");
    searchParams.delete(SEARCH_TEXT_QURY);
    // dispatch(updateSearchText(''))
    setShowPagination(true);

    // dispatch(handleChnageCurrentPage(1))
    // dispatch(handlePerPage(10))

    // dispatch(fetchAllPagesV2({
    //   currentPage: 1,
    //   searchText: '',
    // }));
  };

  const handleSavePageID = (page: Page) => {
    dispatch(getPageId(page._id));
  };

  const handleCopyUrl = (page: Page) => {
    const name = page.name.toLowerCase().replace(/ /g, "_");

    const getPageRoute: any = (type: any, slug: string) => {
      switch (type) {
        case "category":
          return process.env.REACT_APP_FRONTEND_URL + "category/" + slug;
        case "page":
          return process.env.REACT_APP_FRONTEND_URL + slug;
        case "content":
          return process.env.REACT_APP_FRONTEND_URL + slug;
        case "campaign":
          return process.env.REACT_APP_FRONTEND_URL + "campaign/" + slug;
        case "custom":
          return slug;
        case "brand":
          return process.env.REACT_APP_FRONTEND_URL + "brand/" + slug;
        default:
          return process.env.REACT_APP_FRONTEND_URL + slug;
      }
    };

    const url = getPageRoute(page.type, name);
    navigator.clipboard.writeText(url);
    showToast("Page link saved successfully!");
  };

  const handleToggle = (page: Page) => {
    const status = page.status === "draft" ? "publish" : "draft";

    updatePageStatus(page, status)
      .then(() => {
        const pageIndex = searchParams.get(PAGE_NO_QURY)
          ? parseInt(searchParams.get(PAGE_NO_QURY) as string)
          : 1;
        const submitSearchText = searchParams.get(SEARCH_TEXT_QURY)
          ? searchParams.get(SEARCH_TEXT_QURY) || ""
          : "";

        // dispatch(fetchAllPages())
        dispatch(
          fetchAllPagesV2({
            currentPage: pageIndex,
            searchText: submitSearchText,
          })
        );
        showToast("Page status updated successfully!");
      })
      .catch((error) => {
        showToast("Page status update failed!");
      });
  };

  return (
    <div className="homepage__container">
      {permissions.includes("create_page_layout") && (
        <div className="homepage__row--one">
          <form onSubmit={handleSumbit}>
            <input
              value={title}
              type="text"
              placeholder="page name..."
              onChange={handleOnChange}
            />
            <div className="border-none shadow-lg w-full rounded-md overflow-hidden pr-2 bg-white  ">
              <select
                value={pageType}
                onChange={(e) => setPageType(e.target.value)}
                name="types"
                id="types"
                className="p-3 outline-none w-full"
              >
                <option value="page">Page</option>
                <option value="campaign">Campaign</option>
                <option value="campaign-landing">Campaign landing</option>
                <option value="category">Category</option>
                <option value="brand">Brand</option>
                <option value="content">Content</option>
              </select>
            </div>
            <button className="btn" type="submit">
              Add new
            </button>
          </form>
        </div>
      )}
      <div className="homepage__row--two">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSearchText();
          }}
          className="w-full bg-white col-span-3 p-6 gap-6 rounded-md grid grid-cols-3"
        >
          <div className="col-span-2 relative">
            <input
              value={searchText}
              onChange={(e) => handleOnChangeSearchText(e)}
              type="text"
              placeholder="search page..."
            />
            {searchText.length > 0 && (
              <div
                onClick={removeSearchText}
                className="absolute right-2 top-1 z-10 bg-white rounded-full p-1 text-red-500 shadow cursor-pointer"
              >
                <CloseIcon />
              </div>
            )}
          </div>
          <button type="submit" className="btn px-6">
            Search
          </button>
        </form>
      </div>
      <div className="homepage__row--two">
        <ul>
          {getAllPages?.map((page: any) => {
            const pageActive = page.active ?? !!page.is_published_one;

            return (
              <li key={page._id}>
                <div className="grid grid-cols-2 w-full">
                  <div>{page.name}</div>
                  <div>
                    Type:{" "}
                    <span className="text-sm font-light tracking-wide">
                      {page.type}
                    </span>
                  </div>
                </div>
                <div className="btns__wrapper">
                  <button
                    disabled={!!activeSetLoading[page._id]}
                    onClick={(e) => {
                      // console.log(e);
                      e.preventDefault();
                      // input page name popup
                      dispatch(
                        setGlobalPopupElement(
                          <PopupWrapper>
                            <NameInput
                              handleSubmit={async (title: string) => {
                                dispatch(setLoading(true));

                                try {
                                  const res = await getPageDetails(page._id);
                                  const pageData = res.data;
                                  const _title = title.toLowerCase();
                                  const actionObj = {
                                    ...pageData,
                                    name: _title,
                                    status: "draft",
                                  };
                                  dispatch(addPageAsync(actionObj))
                                    .then(unwrapResult)
                                    .then((obj) => {
                                      if (obj) {
                                        showToast("Page created successfully!");
                                        navigate(`/pages/${obj._id}`);
                                      }
                                    })
                                    .catch((error) => {
                                      if (error.code === "ERR_BAD_REQUEST") {
                                        swal(
                                          "Page creation faild! Plz try with different name!"
                                        );
                                      } else {
                                        swal("test");
                                      }
                                    })
                                    .finally(() => {
                                      dispatch(setLoading(false));
                                      dispatch(setGlobalPopupElement(null));
                                    });
                                  setTitle("");
                                } catch (err) {
                                  dispatch(setLoading(false));
                                  dispatch(setGlobalPopupElement(null));
                                }
                              }}
                            />
                          </PopupWrapper>
                        )
                      );
                    }}
                    className={`px-1.5 text-sm rounded-lg border hover:bg-green-500 border-green-500 text-green-500 hover:text-white`}
                  >
                    Clone
                  </button>
                  <a
                    href={
                      process.env.REACT_APP_FRONTEND_PREVIEW_URL +
                      page?._id +
                      ""
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="duplicate__btn">
                      <ChokhIcon />
                    </div>
                  </a>
                  <div
                    className="duplicate__btn"
                    onClick={() => handleCopyUrl(page)}
                  >
                    <DuplicateIcon />
                  </div>
                  {permissions.includes("edit_page_layout") && (
                    <Link
                      to={`/pages/${page._id}`}
                      className="edit__btn"
                      onClick={() => handleSavePageID(page)}
                    >
                      <EditIcon />
                    </Link>
                  )}
                  {page.is_deletable &&
                    permissions.includes("delete_page_layout") && (
                      <div
                        className="delete__btn"
                        onClick={() => {
                          handleDeletePage(page._id);
                        }}
                      >
                        <DeleteIcon />
                      </div>
                    )}

                  <div>
                    {pageActive && (
                      <div
                        className={`w-12 h-fit rounded-full flex items-center p-1 shadow-inner ${
                          page.status === "draft"
                            ? "justify-start bg-gray-400"
                            : "justify-end bg-green-500"
                        }`}
                      >
                        <div
                          onClick={(e) => handleToggle(page)}
                          className="w-4 h-4 rounded-full bg-white drop-shadow-lg cursor-pointer"
                        ></div>
                      </div>
                    )}
                  </div>

                  {/* page active button */}
                  <button
                    disabled={!!activeSetLoading[page._id]}
                    onClick={(e) => {
                      // console.log(e);
                      e.preventDefault();
                      handlePageActive(page._id, pageActive);
                    }}
                    className={`px-1.5 text-sm rounded-lg border ${
                      pageActive
                        ? "bg-green-500 border-green-500 text-white"
                        : "text-orange-500 border-orange-500"
                    }`}
                  >
                    {!!activeSetLoading[page._id]
                      ? "Loading..."
                      : pageActive
                      ? "Active"
                      : "Inactive"}
                  </button>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
      {showPagination && (
        <div>
          <ReactPaginate
            initialPage={
              searchParams.get(PAGE_NO_QURY)
                ? parseInt(searchParams.get(PAGE_NO_QURY) || "1") - 1
                : 0
            }
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            pageCount={totalPage || 0}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"flex items-center justify-center"}
            pageClassName={""}
            pageLinkClassName={
              "w-10 h-10 flex items-center justify-center rounded-full"
            }
            activeClassName={"bg-blue-500 text-white rounded-full shadow-lg"}
            previousClassName={""}
            previousLinkClassName={
              "w-10 h-10 flex items-center justify-center rounded-full"
            }
            breakClassName={
              "w-10 h-10 flex items-center justify-center rounded-full"
            }
            nextClassName={""}
            nextLinkClassName={
              "w-10 h-10 flex items-center justify-center rounded-full"
            }
          />
        </div>
      )}
    </div>
  );
};

export default HomePage;
