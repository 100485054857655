import { createSlice } from "@reduxjs/toolkit";

export interface ShowImagesPopUp {
  value: boolean;
  type: string;
  images: any[];
  image: any;
}

const initialState: ShowImagesPopUp = {
  value: false,
  type: "",
  images: [],
  image: {},
};

export const showImagesPopUpSlice = createSlice({
  name: "showImagesPopUp",
  initialState,
  reducers: {
    openImagesPopUp: (state) => {
      state.value = true;
    },
    closeImagesPopUp: (state) => {
      state.value = false;
    },
    getImagePopUpType: (state, action) => {
      state.type = action.payload;
    },
    getChoosenImages: (state, action) => {
      const newArray = action.payload;
      state.images = [...newArray];
    },
    getSingleImage: (state, action) => {
      state.image = action.payload;
    },
    removeImage: (state, action) => {
      const { index, pageTitle, id } = action.payload;
      // console.log(action.payload)
      state.images = state.images.filter((_, i) => i !== index);
    },
  },
});

export const {
  openImagesPopUp,
  closeImagesPopUp,
  getImagePopUpType,
  getChoosenImages,
  removeImage,
} = showImagesPopUpSlice.actions;

export default showImagesPopUpSlice.reducer;
